
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios, { AxiosResponse } from "axios";

axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

@Component
export default class UploadView extends Vue {
  FILE: any;
  // name: string | Blob;
  data() {
    return {
      FILE: null,
      // name: ''
    }
  }

  public async onFileUpload(event: any): Promise<void> {
    this.FILE = event.target.files[0];
  }

  public async onSubmit(): Promise<void> {
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics/upload";
    // const formData = new FormData()
    // formData.append('swatch', this.FILE, this.FILE.name)
    // // formData.append('name', this.name)
    // axios.post(url, formData, {
    // }).then((res) => {
    //   console.log(res)
    // })

    const formData = new FormData();
    formData.append("image", this.FILE);
    // formData.append("id", 7878);
    axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
  }
}
